/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState } from "react"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import Navbar from "./navBar"
import { gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";


const Layout = ({ children, pageInfo }) => {
  // Similar to componentDidMount and componentDidUpdate:
  // useEffect(() => {
    //   // Update the document title using the browser API
    //   // document.title = `You clicked ${count} times`;
    // });
    useEffect(() => {
              // if (typeof window !== "undefined") {
                gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, ScrollToPlugin);
                gsap.core.globals('ScrollTrigger', ScrollTrigger)
              // }
    
    let sections = gsap.utils.toArray(".pageItem");
    console.log(sections)
    console.log(sections.length)
    if(sections.length ===0)return
    gsap.to(window, {duration: 1, scrollTo: '#content'});
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".pages-placeholder",
        pin: true,
        scrub: 0.5,
        // start: "bottom-=100vh top",
        // start: "top+=100vh top",
        start: "bottom bottom",
        // markers: true,
        // snap: {
        //   // snapTo: "pageItem", // snap to the closest label in the timeline
        //   // snapTo: 1/15,
        //   snapTo: 1/(sections.length-1),
        //   // duration: {min: 0.01, max: 0.1}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
        //   duration: 0.001,
        //   delay: 0.01, // wait 0.2 seconds from the last scroll event before doing the snapping
        //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
        // },
        // snap: 1 / (sections.length - 1),
        // maxDuration: 0.001,
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + document.querySelector(".pages-placeholder").offsetWidth
      }
    });
    // sections.forEach((eachPanel,index)=>{
    //   ScrollTrigger.create({
    //      trigger: eachPanel,
    //     pin: true,
    //     scrub: 1,
    //     // start: "bottom-=100vh top",
    //     // start: "top+=100vh top",
    //     start: "bottom bottom",
    //     markers: true,
    //     snap: {
    //       // snapTo: "pageItem", // snap to the closest label in the timeline
    //       // snapTo: 1/15,
    //       snapTo: 1,
    //       snapTo: sections,
    //       duration: {min: 0.01, max: 0.1}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
    //       delay: 0.01, // wait 0.2 seconds from the last scroll event before doing the snapping
    //       ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
    //     },
    //     // snap: 1 / (sections.length - 1),
    //     // maxDuration: 0.001,
    //     // base vertical scrolling on how wide the container is so it feels more natural.
    //     end: () => "+=" + document.querySelector(".pages-placeholder").offsetWidth
    //   })
    // })
      // xPercent: -100 * (sections.length - 1),
      // ease: "none",
      // scrollTrigger: {
       
      // }
    // });


    // if (typeof window !== `undefined`) {
    //   gsap.registerPlugin(ScrollTrigger)
    //   gsap.core.globals('ScrollTrigger', ScrollTrigger)
    // }
    // let tl = gsap.timeline({
    //   paused: true,
    //   scrollTrigger: {
    //     trigger: '.pageItem',
    //     scrub: true,
    //     pin: true,
    //     // markers: true,
    //     start: 'top top',
    //     end: 'bottom top',
    //   },
    // })
    // tl.to('.overlay', 2, { backgroundColor: 'rgba(0,0,0,.85)' })
    // tl.to('.textOne', 1, { y: -300, autoAlpha: 0 }, 0)
    // tl.to('.textTwo', 1, { y: -300, autoAlpha: 1 }, 0.5)
  }, [])
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site: site {
          siteMetadata {
            title
          }
        }
      }
      `}
      // preview2020: file(relativePath: { eq: "preview2020.jpg" }) {
      //   childImageSharp {
      //     fluid(maxWidth: 1000) {
      //       ...GatsbyImageSharpFluid
      //     }
      //   }
      // }
      // aether: file(relativePath: { eq: "aether_01.jpg" }) {
      //   childImageSharp {
      //     fluid(maxWidth: 1000) {
      //       ...GatsbyImageSharpFluid
      //     }
      //   }
      // }
      render={data => (
        <>
          <Container fluid className="px-0 main">
            <Row noGutters className="justify-content-center">
              <Col>
                <Header siteTitle={data.site.siteMetadata.title} />
              </Col>
            </Row>
            <Navbar pageInfo={pageInfo} />
            {/* <Container className="mt-5">
                <main>{children}</main>
              </Container> */}
            {/* <div className="mt-5"> */}
            {/* <Row noGutters>
            <Col>
              <div className="">
                <main>{children}</main>
                </div>
                </Col>
              </Row> */}
            {children}
          </Container>
          <Container fluid className="px-0">
            <Row noGutters>
              <Col className="footer-col">
                <footer>
                  <span>
                  Design + Konzept + Programmierung Studio RST (<a href="mailto:studio-rst@hotmail.com">studio-rst@hotmail.com</a>)
                  <br/>
                  Graphic Design Artist's Books © Christina Stolz 
                  (<a href="mailto:stolz-rst@hotmail.com">stolz-rst@hotmail.com</a>)
                  </span>
                  {/* <span className="doneBy">
                  Studio RST (studio-rst@live.com)
                  <br/>
                  Konzept + Programmierung © Jonas Rulik (rulik-rst-design@live.com)
                  <br/>
                  Graphic Design © Christina Stolz (stolz-rst-design@live.com)
                  </span> */}
                </footer>
              </Col>
            </Row>
          </Container>
        </>
      )}
    />
  )
}
export default Layout
