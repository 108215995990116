import React from "react"
import { Link, StaticQuery } from "gatsby"
import LinkItem from "../components/linkItem"
import SEO from "../components/seo"
import { graphql } from 'gatsby';
import Img from 'gatsby-image'

import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap"

const CustomNavbar = (props) => (
  <StaticQuery
    query={graphql`
    query  {
      preview2020: file(relativePath: { eq: "preview2020.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aether: file(relativePath: { eq: "aether_01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kugel1: file(relativePath: { eq: "kugel_1_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feder1: file(relativePath: { eq: "feder_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aqua1: file(relativePath: { eq: "aqua_1_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bleistift1: file(relativePath: { eq: "bleistift_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kohle1: file(relativePath: { eq: "kohle_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
    render={data => (
      <>
        <div className="cards">
        <LinkItem title="2020" link="/2020" image="preview-2020.jpg" color="#FDDD00">
          <Img fluid={data.preview2020.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="Aether" link="/aether" image="aether_01.jpg" color="#A1CECB">
          <Img fluid={data.aether.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="kugel_1" link="/kugel_1" image="kugel_1_1.jpg" color="#775700">
          <Img fluid={data.kugel1.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="feder_1" link="/feder" image="feder_1.jpg" color="#813D30">
          <Img fluid={data.feder1.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="aqua_1" link="/aqua_1" image="aqua_1.jpg" color="#5C697A">
          <Img fluid={data.aqua1.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="bleistift_1" link="/bleistift" image="bleistift_1.jpg" color="#1F3331">
          <Img fluid={data.bleistift1.childImageSharp.fluid} />
        </LinkItem>
        <LinkItem title="kohle_1" link="/kohle" image="kohle_1.jpg" color="#BBA4AC">
          <Img fluid={data.kohle1.childImageSharp.fluid} />
        </LinkItem>
        {/* <LinkItem title="Kontakt" link="/contact" image="preview-2020.jpg" desc="K">
        </LinkItem> */}
          {/* <Img fluid={data.preview2020.childImageSharp.fluid} /> */}
          {/* K */}
        <LinkItem title="CV" link="/vita" image="preview-2020.jpg" desc="CV">
          {/* <Img fluid={data.preview2020.childImageSharp.fluid} /> */}
          {/* K */}
        </LinkItem>

        </div>
      </>
    )
    } />)

export default CustomNavbar



// export const fluidImage = graphql`
// fragment fluidImage on File {
//   childImageSharp {
//     fluid(maxWidth: 1000) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
// `;

// export const pageQuery = graphql`
//   query {
//     preview2020: file(relativePath: { eq: "preview2020.jpg" }) {
//       ...fluidImage
//     }
//   }
//   `
        // preview2020: file(relativePath: { eq: "preview2020.jpg" }) {
        //   ...fluidImage
        // }