import React from "react"
import { Link } from "gatsby"
// import Image from './image'

// import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap"

const LinkItem = ({ image, children, link, color, desc }) => {
    //   console.log("pageInfo",pageInfo)
    console.log("image", image)
    return (
        <div
            style={{
                background: color,
            }}
            className={` ${!children || children.length == 0 ? 'linkItemDescDiv' : ''}`}

        >
            <Link to={link} className={`link-item linkItem link-no-style 2020 ${!children || children.length == 0 ? 'linkItemDesc' : ''}`}

            >
                {children || desc}
            </Link>

        </div>
    )
}

export default LinkItem
